import React from 'react';
import Link from 'next/link';

export default function HomeBanner() {
  return (
    <div className="p-5">
      <div className="row align-items-center">
        <p
          className="font-size-display1 text-center mx-auto text-uppercase col-md mb-4 mb-md-0"
          style={{ maxWidth: '53rem' }}
        >
          Hello Moselle! I just wanted to tell you how much I love the mug you made! It’s beautiful! I love the shape of it and the handle..so pretty and nice to hold. I really like the two colours.. the dark inside and speckled outside. I can’t wait to have a coffee in my new mug tomorrow!<br />-Mama Neville
        </p>
        <p
          className="font-size-display1 text-center mx-auto text-uppercase col-md mb-4 mb-md-0"
          style={{ maxWidth: '53rem' }}
        >
          I love my mug, you are so talented! It has a special spot on my kitchen shelf.<br />-Lisa G
        </p>
        <p
          className="font-size-display1 text-center mx-auto text-uppercase col-md"
          style={{ maxWidth: '53rem' }}
        >
          Hey moselle!! Just messaging to let you know I got the mug in perfect condition! Thanks so much again. When I give my friend hers and we will both have a coffee in our mozie mugs!!!!!<br />-Chloe
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-3 mb-5">
        <Link href="/about">
          <a className="d-flex py-3 align-items-center font-color-black borderbottom border-color-black">
            <p className="mr-3">Find out more</p>
            <img src="/icon/arrow-long-right.svg" />
          </a>
        </Link>
      </div>
    </div>
  );
}
