import React, { Component } from 'react';
import Link from 'next/link';
import ProductRow from '../products/ProductRow';
import { connect, ConnectedProps } from 'react-redux';
import { StoreRoot } from '../../store/root';

const mapState = (state: StoreRoot) => ({
  products: state.products
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {

};

class ProductsBanner extends Component<Props> {
  render() {
    const { products } = this.props;

    return (
      <div className="custom-container py-5 my-5">
        <div className="d-flex flex-column align-items-center mb-5 pb-4">
          <p className="font-color-medium mb-4">
            Introducing Our Latest Collection
          </p>
          <p
            className="text-center font-size-display2 mb-3 font-weight-medium"
            style={{ maxWidth: '32rem' }}
          >
            Our pieces are unique, just like you.
          </p>
          <Link href="/collection">
            <a className="d-flex py-3 align-items-center font-color-black borderbottom border-color-black">
              <p className="mr-3">See more pieces</p>
              <img src="/icon/arrow-long-right.svg" />
            </a>
          </Link>
        </div>
        <ProductRow products={products.slice(0, 4)} />
      </div>
    );
  }
}

export default connector(ProductsBanner);
