import React from 'react';

const Footer = () => {
  return <footer className="pt-5">
    <div className="pt-md-5">
      <div className="bg-brand300">
        <div className="custom-container d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="pt-5 pb-0 pt-md-4 pb-md-4 d-flex align-items-center flex-wrap justify-content-center">
            <a
              href="https://paitoanderson.com/"
              className="font-color-brand font-size-caption text-uppercase text-center"
              rel="noopener noreferrer"
              target="_blank"
            >
              Made with &hearts;
            </a>
          </div>
          <div className="font-color-brand font-size-caption py-4 text-right">
          <a
              href="https://www.instagram.com/mizzfuzz/"
              className="font-color-brand font-size-caption text-uppercase text-center"
              rel="noopener noreferrer"
              target="_blank"
            >
              &copy; { new Date().getFullYear() } Moselle Anderson
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>;
};

export default Footer;
