import React from "react";

const useScrollListener = (handleScroll: (e: Event) => void) => {

  const listenToScroll = React.useCallback(
    (e: Event) => {
      requestAnimationFrame(() => handleScroll(e));
    },
    [handleScroll]
  );

  React.useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);
};

export default useScrollListener;