
import { Product } from '@chec/commerce.js/types/product';
import React from 'react';
import ProductCard from './ProductCard';

type Props = {
  products: Product[];
}

export default function ProductRow({ products }: Props) {

  const reg = /(<([^>]+)>)/ig;

  return (
    <div className="row mb-5">
      {products.map((product) => (
        <div key={product.id} className="col-6 col-sm-6 col-lg-3">
          <ProductCard
            permalink={product.permalink}
            image={product.media.source}
            name={product.name}
            price={product.price.formatted_with_symbol}
            description={product.description && product.description.replace(reg, '')}
            soldOut={product.is.sold_out}
          />
        </div>
      ))}
    </div>
  );
}
