import React, { Component } from 'react';
import Link from 'next/link';
import { connect, ConnectedProps } from 'react-redux';
import { StoreRoot } from '../../store/root';

const mapState = (state: StoreRoot) => ({
  categories: state.categories
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {

};

class CategoryBanner extends Component<Props> {

  render() {
    const { categories } = this.props;

    return (
      <div className="bg-brand300 py-5 collection-banner">
        <div className="custom-container py-5">
          <p className="font-size-display2 my-3 py-5 text-center font-family-secondary">
            Categories
          </p>

          <div className="row">
            {categories.map((item, index) => (
              <div
                key={`category-item-${index}`}
                className="col-12 col-md-4 collection-item mb-5"
              >
                <Link href={`/collection#${item.slug}`} key={item.id}>
                  <a className="align-items-center font-color-black flex-column cursor-pointer mb-5">
                    <div>
                      <div
                        className="mb-4 w-100 collection-item-image"
                        style={{
                          background: `url("/images/collection/${item.slug}.jpg") center center/cover`
                        }}
                      />
                      <p className="mb-2 font-size-heading text-center">
                        {item.name}
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(CategoryBanner);
